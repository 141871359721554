<template>
  <div>
    <v-expansion-panels v-model="panel" tile>
      <v-expansion-panel readonly>
        <v-expansion-panel-header>
          <h4>Manage Appointment Blocks</h4>
        </v-expansion-panel-header>
        <v-divider></v-divider>
        <v-expansion-panel-content>
          <div v-show="showForms">
            <v-row>
              <v-col>
                <v-btn-toggle v-model="toggleType" mandatory>
                  <v-btn>
                    Bulk
                  </v-btn>
                  <v-btn>
                    Single
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-card flat>
              <v-card-text v-show="toggleType === 0">
                <v-form ref="bulk">
                  <v-row>
                    <v-col cols=12>
                      <v-select
                      :rules="required"
                        dense
                        outlined
                        label="Select Location"
                        :items="locations"
                        item-value="id"
                        item-text="locationName"
                        v-model="availability.location"

                      >
                        <template v-slot:selection="{ item }">
                          {{ item.locationName }}
                          <manufacturer-chip :manufacturerId="item.vaccineManufacturerId" :manufacturerName="item.vaccineManufacturerName" v-if="item.vaccineManufacturerId > 0"></manufacturer-chip>
                          <dose-type-chip :doseType="item.vaccineDoseType" v-if="item.vaccineDoseType"></dose-type-chip>
                          <!-- render selected items -->
                        </template>
                        <template  v-slot:item="{ item }">
                          {{ item.locationName }}
                          <manufacturer-chip :manufacturerId="item.vaccineManufacturerId" :manufacturerName="item.vaccineManufacturerName" v-if="item.vaccineManufacturerId > 0"></manufacturer-chip>
                          <dose-type-chip :doseType="item.vaccineDoseType" v-if="item.vaccineDoseType"></dose-type-chip>
                          <!-- render items when the select is open -->
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xs="12" md="6">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="availability.startDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :rules="required"
                            :error="bulkDateError"
                            v-model="availability.startDate"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="availability.startDate" no-title scrollable :min="currentDate" @change="$refs.menu.save(availability.startDate); menu = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="availability.endDate"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :rules="required"
                            :error="bulkDateError"
                            v-model="availability.endDate"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="availability.endDate" no-title scrollable :min="currentDate" @change="$refs.menu3.save(availability.endDate); menu3 = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="hourValues"
                        :rules="required"
                        :error="bulkHourError || bulkStartHourError"
                        label="Hour Start"
                        dense
                        outlined
                        item-text="value"
                        item-value="key"
                        v-model="availability.hour_start"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="hourValues"
                        item-text="value"
                        item-value="key"
                        :rules="required"
                        :error="bulkHourError"
                        label="Hour End"
                        dense
                        outlined
                        v-model="availability.hour_end"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="numberRule"
                        label="Block Minute Duration"
                        type="number"
                        dense
                        outlined
                        v-model="availability.appt_duration"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        :rules="numberRule"
                        label="Vaccines Per Block"
                        type="number"
                        dense
                        outlined
                        v-model="availability.capacity"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-checkbox dense label="Include Weekends" v-model="availability.include_weekends"></v-checkbox>
                      <span v-if="bulkHourError || bulkDateError" class="error-text">Make sure your end dates/hours aren't before your start dates/hours</span>
                      <span v-if="bulkStartHourError" class="error-text">Make sure your start hour and date are in the future</span>
                      <span v-if="noBlocksCreatedError" class="error-text">No blocks will be created with the entered day span without include weekends checked.</span>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-text v-show="toggleType === 1">
                <v-form ref="single">
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :rules="required"
                        outlined
                        label="Location"
                        :items="locations"
                        item-value="id"
                        item-text="locationName"
                        v-model="single.location"
                        dense
                      >
                        <template v-slot:selection="{ item }">
                          {{ item.locationName }}
                          <manufacturer-chip :manufacturerId="item.vaccineManufacturerId" :manufacturerName="item.vaccineManufacturerName" v-if="item.vaccineManufacturerId > 0"></manufacturer-chip>
                          <dose-type-chip :doseType="item.vaccineDoseType" v-if="item.vaccineDoseType"></dose-type-chip>
                          <!-- render selected items -->
                        </template>
                        <template  v-slot:item="{ item }">
                          {{ item.locationName }}
                          <manufacturer-chip :manufacturerId="item.vaccineManufacturerId" :manufacturerName="item.vaccineManufacturerName" v-if="item.vaccineManufacturerId > 0"></manufacturer-chip>
                          <dose-type-chip :doseType="item.vaccineDoseType" v-if="item.vaccineDoseType"></dose-type-chip>
                          <!-- render items when the select is open -->
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        :return-value.sync="single.start_date"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :rules="required"
                            v-model="single.start_date"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="single.start_date" no-title scrollable :min="currentDate" @change="$refs.menu4.save(single.start_date); menu4 = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="required"
                        label="Start Time"
                        :error="singleHourErrorShow"
                        dense
                        outlined
                        v-model="single.start_hour"
                        value="12:30"
                        type="time"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        :rules="required"
                        label="End Time"
                        :error="singleHourErrorShow"
                        dense
                        outlined
                        v-model="single.end_hour"
                        value="12:30"
                        type="time"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :rules="numberRule"
                        label="capacity"
                        dense
                        outlined
                        v-model="single.capacity"
                      ></v-text-field>
                      <span v-if="singleHourErrorShow" class="error-text">{{ singleHourError }}</span>
                    </v-col>
                  </v-row>
                </v-form>
            </v-card-text>
          
            <v-card-actions v-show="toggleType === 0">
              <v-btn color="primary" @click="reset">Reset</v-btn>
              <v-btn color="success" @click="buildBlocks" :loading="loadingInsertBlocks">Build Blocks</v-btn>
            </v-card-actions>
            <v-card-actions v-show="toggleType === 1">
              <v-btn color="primary" @click="reset">Reset</v-btn>
              <v-btn color="success" @click="saveSingle">Save</v-btn>
              <!-- <v-btn color="error" @click="endSingle">Delete</v-btn> -->
              <v-btn color="error" @click="reviewDeleteSingle" :disabled="!single.id">Delete</v-btn>
            </v-card-actions>
          </v-card>
          <v-divider class="mt-3"></v-divider>
          </div>
        <v-row>
          <v-col cols="12">
            <block-list
              @updateMultiSelect="updateShowForms"
              @updateBlockForm="populateBlockForm"
              @updatePage="updatePage"
              :page="currentBlockPage"
              :key="renderKey"
            ></block-list>
          </v-col>
        </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- block appt release list dialog -->
    <v-dialog v-model="showBlockReleaseList" max-width="800px">
      <block-release-list :key="renderKeyBulkRelease" :block="selectedBlock" @closeReleaseList="closeBlockReleaseList"></block-release-list>
    </v-dialog>

      <!-- block appt capacity list dialog -->
    <v-dialog v-model="showBlockCapacityList" max-width="800px">
      <block-capacity-list :key="renderKeyCapacityRelease" :block="selectedBlock" @closeCapacityList="closeBlockCapacityList"></block-capacity-list>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      hourValues: [
        { key: 0, value: '00' },
        { key: 1, value: '01' },
        { key: 2, value: '02' },
        { key: 3, value: '03' },
        { key: 4, value: '04' },
        { key: 5, value: '05' },
        { key: 6, value: '06' },
        { key: 7, value: '07' },
        { key: 8, value: '08' },
        { key: 9, value: '09' },
        { key: 10, value: '10' },
        { key: 11, value: '11' },
        { key: 12, value: '12' },
        { key: 13, value: '13' },
        { key: 14, value: '14' },
        { key: 15, value: '15' },
        { key: 16, value: '16' },
        { key: 17, value: '17' },
        { key: 18, value: '18' },
        { key: 19, value: '19' },
        { key: 20, value: '20' },
        { key: 21, value: '21' },
        { key: 22, value: '22' },
        { key: 23, value: '23' }
      ],
      required: [
        v => !!v || 'Required Field'
      ],
      numberRule: [
        v => !!v || 'Required Field',
        v => v > 0 || 'Please enter a positive number'
      ],
      bulkDateError: false,
      bulkHourError: false,
      bulkStartHourError: false,
      singleHourError: null,
      singleHourErrorShow: false,
      panel: 0,
      toggleType: 0,
      renderKey: 0,
      availability: { include_weekends: false },
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      currentBlockPage: 0,
      single: {
        start_hour: '12:00',
        end_hour: '12:00'
      },
      selectedBlock: {},
      showBlockReleaseList: false,
      renderKeyBulkRelease: 0,
      showBlockCapacityList: false,
      renderKeyCapacityRelease: 0,
      showForms: true,
      noBlocksCreatedError: false
    }
  },
  components: {
    blockList: () => import('./components/blockList'),
    blockReleaseList: () => import('./components/blockReleaseList'),
    blockCapacityList: () => import('./components/blockCapacityList'),
    manufacturerChip: () => import('../../../vaccineManufacturer/components/manufacturerChip'),
    doseTypeChip: () => import('../../../vaccineManufacturer/components/doseTypeChip')
  },
  props: {
    value: Boolean
  },
  watch: {
    availability: {
      handler: function (val) {
        this.validateBulkTimes()
      },
      deep: true
    },
    single: {
      handler: function (val) {
        this.validateSingleTimes()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('vaccineLocations', ['locations', 'loadingInsertBlocks']),
    currentDate: {
      get: function () {
        return moment(new Date()).format('YYYY-MM-DD')
      }
    },
    show: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapActions('vaccineLocations', ['insertLocation', 'updateLocation', 'deleteLocation', 'bulkInsertBlocks', 'insertSingle', 'updateSingle', 'deleteSingle']),
    ...mapActions('appointmentBlock', ['getMultiBlockAppts']),
    updateShowForms(e) {
      this.showForms = e === 1 ? false : true;
    },
    updatePage: function (page) {
      this.currentBlockPage = page
    },
    convertToLocal: function (date) {
      return moment.utc(date).local()
    },
    saveSingle: function () {
      if (!this.$refs.single.validate() || !this.validateSingleTimes()) {
        this.singleHourErrorShow = true
        return false
      }

      if(this.single.id) {
          this.getMultiBlockAppts({ ...this.single, blocks: this.single.id})
          .then(response => {
            if(response.length > this.single.capacity) {
                this.renderKeyBulkRelease++;
                this.selectedBlock =  this.single
                this.showBlockCapacityList = true;
            } else{
              this.saveBlock();
            }
          });
      } else {
        this.saveBlock();
      }
    },
    saveBlock: function () {
      this.single.datetime_start = moment((this.single.start_date + ' ' + this.single.start_hour)).toISOString(true)
      this.single.datetime_end = moment((this.single.start_date + ' ' + this.single.end_hour)).toISOString(true)
      this.single.capacity = parseInt(this.single.capacity)

      if (this.single.id) {
        this.updateSingle(this.single).then((response) => {
          this.renderKey++
        })
      } else {
        this.bulkInsertBlocks([this.single])
              .then((response) => {
                this.renderKey++
            })
      }
      this.reset()
    },
    reviewDeleteSingle() {
      this.renderKeyBulkRelease++;
      if(this.single.id) {
        this.selectedBlock =  this.single
        this.showBlockReleaseList = true;
      }
    },
    closeBlockReleaseList() {
      this.single = {};
      this.selectedBlock = {};
      this.showBlockReleaseList = false;
      this.renderKey++;
    },
    closeBlockCapacityList() {
      this.showBlockCapacityList = false;
    },
    validateSingleTimes: function () {
      let error = false

      if (this.single.start_hour && this.single.end_hour && this.single.start_date) {
        const start = moment((this.single.start_date + ' ' + this.single.start_hour))
        const end = moment((this.single.start_date + ' ' + this.single.end_hour))
        const time = end.diff(start)
        if (time <= 0) {
          this.singleHourError = 'Your start time must be before your end time'
          this.singleHourErrorShow = true
          error = true
        } else if (start.isBefore(moment())) {
          this.singleHourError = 'Your start time must be a time in the future'
          this.singleHourErrorShow = true
          error = true
        } else {
          this.singleHourErrorShow = false
          this.singleHourError = null
          error = false
        }
      }
      return !error
    },
    validateBulkTimes: function () {
      let error = false
      if (this.availability.startDate && this.availability.endDate) {
        const start = moment(this.availability.startDate)
        const end = moment(this.availability.endDate)
        const time = end.diff(start)


        if (time < 0) {
          this.bulkDateError = true
          error = true
        } else {
          this.bulkDateError = false
        }
      }
      if (this.availability.hour_end && this.availability.hour_start) {

        if (this.availability.hour_end <= this.availability.hour_start) {
          this.bulkHourError = true
          error = true
        } else {
          this.bulkHourError = false
        }
      }

      if (this.availability.startDate && this.availability.hour_start) {
        const startDateTime = moment((this.availability.startDate + ' ' + this.availability.hour_start + ':00'))
        if (startDateTime.isBefore(moment())) {
          this.bulkStartHourError = true
          error = true
        } else {
          this.bulkStartHourError = false
        }
      }

      return !error
    },
    populateBlockForm: function (e) {
      if (e.length > 0) {
        this.single = { ...e[0] }
        let startDateTime = this.convertToLocal(e[0].datetime_start)
        let endDateTime = this.convertToLocal(e[0].datetime_end)
        this.single.start_date = startDateTime.format('YYYY-MM-DD')
        this.single.start_hour = startDateTime.format('HH:mm')
        this.single.end_date = endDateTime.format('YYYY-MM-DD')
        this.single.end_hour = endDateTime.format('HH:mm')
        this.toggleType = 1
      } else {
        this.reset()
      }
    },
    reset: function () {
      this.single = { }
      this.$refs.single.reset()
      this.$refs.bulk.reset()
      this.availability = { include_weekends: false }
    },
    buildBlocks: function () {
      this.noBlocksCreatedError = false;

      if (!this.validateBulkTimes() || !this.$refs.bulk.validate()) {
        return false
      }


      const blockEnd = moment(this.availability.endDate).add(this.availability.hour_end, 'hours')
      let dayStart = moment(this.availability.startDate).add(this.availability.hour_start, 'hours')
      let dayEnd = moment(this.availability.startDate).add(this.availability.hour_end, 'hours')
      let dayStartIterator = moment(this.availability.startDate).add(this.availability.hour_start, 'hours')
      let dayEndIterator = moment(this.availability.startDate).add(this.availability.hour_start, 'hours')

      var result = []

      while (dayStart.isSameOrBefore(blockEnd)) {
        while (dayStartIterator.isBefore(dayEnd)) {
          dayEndIterator.add(this.availability.appt_duration, 'minutes')

          if (this.availability.include_weekends === true) {
            result.push({
              datetime_start: dayStartIterator.format(),
              datetime_end: dayEndIterator.format(),
              location: this.availability.location,
              capacity: parseInt(this.availability.capacity)
            })
          } else if (
            this.availability.include_weekends === false &&
            dayStartIterator.day() !== 0 &&
            dayStartIterator.day() !== 6
          ) {
            result.push({
              datetime_start: dayStartIterator.format(),
              datetime_end: dayEndIterator.format(),
              location: this.availability.location,
              capacity: parseInt(this.availability.capacity)
            })
          }

          dayStartIterator.add(this.availability.appt_duration, 'minutes')
        }

        dayEnd.add(1, 'days')
        dayStart.add(1, 'days')
        dayStartIterator.add(1, 'days').set('hour', this.availability.hour_start)
        dayEndIterator.add(1, 'days').set('hour', this.availability.hour_start)
      }


      if(result.length > 0){
        this.bulkInsertBlocks(result).then((response) => {
          this.renderKey++
          this.reset()
        })
      } else {
        console.log('no results to save');
        this.noBlocksCreatedError = true;
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
.error-text {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
}
</style>